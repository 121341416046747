import React from "react";
import { Button, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VoiceLogo from "../../../assets/images/logo/quicksmart-1_480.png";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

const MainContent = () => {
  const navigate = useNavigate()
  return (
    <section className={styles.main_container}>
      <Grid className={styles.main_box}>
        <Grid sx={{ color: "rgb(255 255 255 / 1)" }}>
          <div data-aos="fade-up" data-aos-duration="3000">
            <h1 >First Conversational<br /> AI Voice Agents</h1>
          </div>
          <Grid>
            <p>
              <span>
                Experience the Future with Quick & Smart Al Assistant:
                Indistinguishably Human, Unbelievably Efficient
              </span>
            </p>
          </Grid>
          <Grid>
            <Grid className={styles.mike_container}>
              <img src={VoiceLogo} alt="VoiceLogo" />
            </Grid>
            <Grid className={styles.give_btn_cotainer}>
              <Button className={styles.give_btn} href="#trail">AI Agents Drive Business Success</Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4} className={styles.Try_btn}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: "24px",
                  height: "48px",
                  padding: ".5rem 2rem",
                  background: "#DE283B",
                  flexWrap: "nowrap",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                  m: 1,
                  "&:hover": {
                    color: "white",// Change text color to white on hover
                    background: "#DE283B",
                  }
                }}
                onClick={() => navigate('/tryout')}
              >
                Try Phone Calls
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className={styles.Try_btn}>
              <Button
                fullWidth
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
                sx={{
                  borderRadius: "24px",
                  height: "48px",
                  padding: ".5rem 2rem",
                  background: "transparent",
                  flexWrap: "nowrap",
                  color: "white",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                  m: 1,
                  "&:hover": {
                    color: "white" // Change text color to white on hover
                  }
                }}
                href="https://calendly.com/callanywhere/30min"

              >
                Get in Contact
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} className={styles.Try_btn}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  borderRadius: "24px",
                  height: "48px",
                  padding: ".5rem 2rem",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                  color: "white",
                  fontWeight: 700,
                  m: 1,
                  "&:hover": {
                    color: "white" // Change text color to white on hover
                  }
                }}
                onClick={() => navigate('/demo')}
              >
                Try web call
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default MainContent;
